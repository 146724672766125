.space {
  flex: 1 1 auto;
}
.inputsWrapper {
  display: flex;
  align-items: center;
}
.react-daterange-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 56px;
}

.react-daterange-picker-presets {
  margin-left: 189px;
}


.react-daterange-picker__fullWidth {
  width: 100%;
  max-width: 100%;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  padding: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #cecdd1;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
 z-index: 1500;
}

.react-daterange-picker__calendar--closed {
  display: none;
}

.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}
